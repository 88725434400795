import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"
import ImageDownloadSocial from "../../../components/ImageDownloadSocial"

const IndexPage = () => {
    const root = "/img/download/conference/workshops"

    return (
        <Layout pageTitle="Workshops - Standard Graphics">
            <Row>
                <Col>
                    <Link to="/conference/workshops/workshops-index">Back</Link>
                </Col>
            </Row>
            

            <ImageDownload
                root={root}
                name="Workshop Promo"
                filename="workshop-promo"
                text="Click to see a selection of promo graphics for your use. You can use and adapt the marketing copy below for Workshops & Women&apos;s Business Conference"
                squareHref="https://www.canva.com/design/DAFnMh4oW8Y/-wMfCxBCsN2fuVSFlGr74g/view?utm_content=DAFnMh4oW8Y&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFnMqEGux8/D1b_o1yV3ZD4KatSFmch8Q/view?utm_content=DAFnMqEGux8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/1H8r8KhTSL-vtNaY0j8ka4W88880IcQh7Bhd9rfFH9Kc/edit?usp=sharing"
            />


        </Layout>
    )
}

export default IndexPage
